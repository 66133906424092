<template>
  <div>
    <section class="main mt-5">
      <div class="d-flex flex-column flex-md-row mb-5">
        <div class="col-12 col-md-6">
          <h1 class="f-secondary text-gradient mb-5">Why TalentFind?</h1>
          <ul class="m-0">
            <li class="list-icon mb-2">
              Find thousands of relevant roles at your fingertips
            </li>
            <li class="list-icon mb-2">
              Get guidance through your healthcare training pathway
            </li>
            <li class="list-icon mb-2">
              Access accredited language training, upskilling and E-learning
              opportunities
            </li>
            <li class="list-icon mb-2">Build global healthcare connections</li>
            <li class="list-icon mb-2">Develop your career in healthcare</li>
            <li class="list-icon mb-2">
              Be part of a global healthcare community
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-6">
          <div class="card-bg" id="register-form">
            <h2 class="f-secondary font-40 mb-5 text-gradient-2">
              Join the global health careers marketplace today
            </h2>
            <p>
              Already registered with Talentfind Marketplace. Click 
              <a href="/web/login" class="text-second bold">login</a>
            </p>

            <div v-if="showBasicQuestions">
              <ValidationObserver ref="registerForm" v-slot="{ invalid }">
                <form @submit.prevent="registerForm">
                  <!-- Email Input -->
                  <label for="email">Email*</label>
                  <ValidationProvider
                    rules="required|email"
                    v-slot="{ errors }"
                    name="email"
                  >
                    <input
                      type="email"
                      v-model="user.email"
                      id="email"
                      disabled
                    />
                    <span class="error-text">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <!-- Confirm Email Input -->
                  <label for="confirmEmail">Confirm Email*</label>
                  <ValidationProvider
                    rules="required|email|confirmed:email"
                    v-slot="{ errors }"
                    name="Confirm Email"
                  >
                    <input
                      type="email"
                      v-model="user.confirmEmail"
                      id="confirmEmail"
                      disabled
                    />
                    <span class="error-text">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <!-- Title Dropdown -->
                  <label for="title">Title*</label>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    name="Title"
                  >
                    <select v-model="user.title" id="title" class="">
                      <option
                        v-for="title in titles"
                        :key="title.code"
                        :value="title.label"
                      >
                        {{ title.label }}
                      </option>
                    </select>
                    <span class="error-text">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <label for="firstname">First Name*</label>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    name="First Name"
                  >
                    <input
                      type="text"
                      id="firstname"
                      name="firstname"
                      v-model="user.firstName"
                    />
                    <span class="error-text">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <label for="surname">Surname*</label>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    name="Surname"
                  >
                    <input
                      type="text"
                      id="surname"
                      name="surname"
                      v-model="user.surName"
                    />
                    <span class="error-text">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <!-- Phone Number with Country Code -->
                  <label for="phone">Mobile Phone Number*</label>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    name="Phone Number"
                  >
                    <div class="d-flex">
                      <select v-model="user.dialingCode" class="w-25">
                        <option
                          v-for="(code, index) in dialingCodeWithCountryCode"
                          :key="index"
                          :value="code.dialing_code"
                        >
                          {{ code.label }}
                        </option>
                      </select>
                      <input
                        type="number"
                        v-model="user.phoneNumber"
                        id="phone"
                        class="w-75"
                      />
                    </div>
                    <span class="error-text">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <!-- Profession Dropdown -->
                  <label for="profession">Profession*</label>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    name="Profession"
                  >
                    <select v-model="user.profession" id="profession" class="">
                      <option
                        v-for="profession in candidateType"
                        :key="profession.code"
                        :value="profession.label"
                      >
                        {{ profession.label }}
                      </option>
                    </select>
                    <span class="error-text">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <!-- Submit Button -->
                  <button
                    type="submit"
                    class="btn btn-primary d-block"
                    :disabled="isLoading"
                  >
                    <span v-if="isLoading" class="spinner"></span>
                    <span v-else>Register Now</span>
                  </button>
                </form>
              </ValidationObserver>
            </div>
            <div v-else>
              <ValidationObserver ref="loginForm" v-slot="{ invalid }">
                <form @submit.prevent="submitForm">
                  <!-- Email Input -->
                  <label for="email">Email*</label>
                  <ValidationProvider
                    rules="required|email"
                    v-slot="{ errors }"
                    name="email"
                  >
                    <input type="email" v-model="user.email" id="email" />
                    <span class="error-text">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <!-- Confirm Email Input -->
                  <label for="confirmEmail">Confirm Email*</label>
                  <ValidationProvider
                    rules="required|email|confirmed:email"
                    v-slot="{ errors }"
                    name="Confirm Email"
                  >
                    <input
                      type="email"
                      v-model="user.confirmEmail"
                      id="confirmEmail"
                    />
                    <span class="error-text">{{ errors[0] }}</span>
                  </ValidationProvider>

                  <!-- Submit Button -->
                  <button
                    type="submit"
                    class="btn btn-primary d-block"
                    :disabled="isLoading"
                  >
                    <span v-if="isLoading" class="spinner"></span>
                    <span v-else>Next</span>
                  </button>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal 1 -->
      <Modal v-if="showModal.modal1" @close="showModal.modal1 = false">
        <div class="text-left line-height-40">
          <h1 class="f-secondary text-gradient">
            Welcome, {{ existUserData.first_name }}
          </h1>
          <h5 class="bold">Start your journey with us by registering today.</h5>
          <h5 class="bold">Registration will enable you to:</h5>
          <ul>
            <li>Access to your personalised portal</li>
            <li>
              Manage and update your profile, preferences, CV and other
              documentation
            </li>
            <li>Access accredited training opportunities</li>
            <li>View and apply for latest job opportunities</li>
            <li>
              See notifications, updates and feedback from the recruiting teams
            </li>
            <li>Or have a private profile and still access the Marketplace</li>
          </ul>
          <h5 class="bold">with more to come...</h5>
          <div>
            <button class="btn btn-primary mr-5" @click="closeModal('modal1')">
              Complete Registration
            </button>
            <button class="btn btn-primary" @click="registerLater()">
              I'll finish it later
            </button>
          </div>
        </div>
      </Modal>

      <!-- Modal 2 -->
      <Modal v-if="showModal.modal2" @close="showModal.modal2 = false">
        <div class="text-left line-height-40">
          <h1 class="f-secondary text-gradient">
            Welcome back, {{ existUserData.first_name }}
          </h1>
          <h5 class="bold">
            Your profile details are already registered with TalentFind World, a
            member of the TalentFind Marketplace.
          </h5>
          <div>
            <button class="btn btn-primary mr-5" @click="closeModal('modal2')">
              Register Now
            </button>
          </div>
        </div>
      </Modal>

      <!-- Modal 3 -->
      <Modal v-if="showModal.modal3" @close="showModal.modal3 = false">
        <div class="text-left line-height-40">
          <h1 class="f-secondary text-gradient">
            Welcome, {{ existUserData.first_name }}
          </h1>
          <h5 class="bold">
            An email will be sent to you shortly with a link to complete your
            registration.
          </h5>
        </div>
      </Modal>

      <!-- Modal 4 -->
      <Modal v-if="showModal.modal4" @close="showModal.modal4 = false">
        <div class="text-left line-height-40">
          <h1 class="f-secondary text-gradient">
            Welcome back, {{ existUserData.candidate_name }}
          </h1>
          <h5 class="bold">
            You are already registered with TalentFind Marketplace. Please click
            "Login" to access your account.
          </h5>
          <div>
            <button class="btn btn-primary mr-5" @click="closeModal('modal4')">
              Login
            </button>
          </div>
        </div>
      </Modal>
    </section>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import { required, email, confirmed } from "vee-validate/dist/rules";
import { mapActions, mapGetters } from "vuex";
import { redirectToTFWRegistrationUrl } from "../../helpers/helper"

// Extend validation rules
extend("required", { ...required, message: "This field is required" });
extend("email", { ...email, message: "Invalid email address" });
extend("confirmed", { ...confirmed, message: "Emails do not match" });

import Modal from "./ModalComponent.vue";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      user: {
        campaignName: "Main Registration",
        email: "",
        confirmEmail: "",
        title: "",
        dialingCode: "",
        phoneNumber: "",
        firstName: "",
        surName: "",
        profession: "",
      },
      isLoading: false,
      showBasicQuestions: false,
      existUserData: {},
      showModal: {
        modal1: false,
        modal2: false,
        modal3: false,
        modal4: false,
      },
    };
  },
  computed: {
    ...mapGetters(["dialingCodeWithCountryCode", "titles", "candidateType"]),
  },
  methods: {
    ...mapActions([
      "userExistInCampaign",
      "initQuickRegisterFetchOptions",
      "campaignRegistration",
      "campaignRegisterLater",
      "checkCandidateExistInCampaignPreReg",
    ]),
    async submitForm() {
      const isValid = await this.$refs.loginForm.validate();
      if (isValid) {
        this.isLoading = true;
        const data = {
          campaign_name: this.user.campaignName,
          email: this.user.email.trim().toLowerCase(),
        };
        try {
          const response =  await this.checkCandidateExistInCampaignPreReg(data.email);
          if(response?.data?.is_pre_reg_candidate || response?.data?.is_exist_user) {
            this.existUserData = {
              ...response.data,
            };
            this.$router.push(redirectToTFWRegistrationUrl(data.email));
          } else {
            this.showBasicQuestions = true;
          }
        } catch (error) {
          console.error("Error:", error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    async registerForm() {
      const isValid = await this.$refs.registerForm.validate();
      if (isValid) {
        this.isLoading = true;
        try {
          const data = {
            campaign_name: this.user.campaignName,
            first_name: this.user.firstName,
            surname: this.user.surName,
            email: this.user.email.trim().toLowerCase(),
            country_code: this.user.dialingCode,
            phone: this.user.phoneNumber,
            profession: this.user.profession,
          };

          const response = await this.campaignRegistration(data);
          if (response.data.registration_url) {
            this.existUserData.first_name = this.user.firstName;
            this.existUserData = {
              ...this.existUserData,
              ...response.data,
            };
            this.showModal.modal1 = true;
          }
          this.showBasicQuestions = false;
        } catch (error) {
          console.error("Error:", error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    closeModal(modal_name) {
      this.showModal[modal_name] = false;
      if (this.existUserData?.registration_url) {
        window.location.href = this.existUserData?.registration_url;
      }
    },
    async registerLater() {
      await this.campaignRegisterLater({
        email: this.user.email,
        campaign_name: this.user.campaignName,
      });
      this.showModal.modal1 = false;
    },
  },
  mounted() {
    this.initQuickRegisterFetchOptions();
  },
  beforeDestroy() {
    this.user = {
      campaignName: "Main Registration",
      email: "",
      confirmEmail: "",
      title: "",
      dialingCode: "",
      phoneNumber: "",
      firstName: "",
      surName: "",
      profession: "",
    };
    this.isLoading = false;
    this.showBasicQuestions = false;
    this.existUserData = {};
  },
};
</script>

<style lang="scss" scoped>
@import "../../../src/assets/scss/stm.scss";
</style>
